.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../../assets/loader_bg.png'); /* Path to your image */
    width: 100vw;
    background-size: cover; /* Cover the entire area */
    background-position: center; /* Center the background image */
    position: fixed; /* Make the loader overlay */
    top: 0;
    left: 0;
    z-index: 1000;
}

.loader .loaderText {
    position: absolute;
    bottom: 40px;
    color: #fff;
    background-color: #000;
    padding: 10px 50px;
}