.header {
}
.header .rewardList {
    font-family: 'Vakansy', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 20px;
    gap: 20px
}
.header .rewardList li {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 30px;
}
.header .rewardList .rewardIco {
    height: 30px;
}