body {
	font-family: 'Helvetica Neue', arial, sans-serif;
    margin: 0;
}

main {
    margin: 0 20px;
}

ul {
    list-style-type: none;
    padding: 0;
}

.icoRanking {

}