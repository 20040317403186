.footer {

}

.footer nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    color: #fff;
    margin-bottom: 10px;
}

.footer nav a {
    text-decoration: none;
    color: #808080;
    padding: 10px;
}

.footer nav a.active {
    color: #000;
}

.footer nav a.mainButton {
    font-family: 'Vakansy', sans-serif;
    color: #000;
    font-weight: 800;
    font-size: 20px;
    #border: 1px solid #abd3f3;
    border-radius: 10px;
    letter-spacing: 3px;
    padding: 10px 40px;
    background: linear-gradient(#fffbd8, #ffefc2);
}

.footer nav a img.ico {
    height: 20px;
}

.footer nav a img path {
    fill: red;
    color: red;
}